<template>
  <div class="app-container">
    <nav>
      <div class="tips">
        <el-button type="primary" class="btn" @click="submitForm('employForm')" :loading='isLoading' :disabled="showLoading" v-if="employData.voucherId == '' || employData.voucherId == undefined">保存</el-button>
        <el-button class="btn able" @click="back" plain>取消</el-button>
      </div>
    </nav>
    <div class="container">
      <div class="headline">基本信息</div>
      <el-form ref="employForm" :rules="rules" :model="employData" label-width="8rem" class="employ_form" v-loading="showLoading">
          <!-- 开票类型：type -->
          <el-row class="part">
              <el-col :span="8" :offset="0">
                  <el-form-item label="开票日期" prop='billingDate'>
                      <el-date-picker v-model="employData.billingDate" placeholder="请选择开票日期" value-format="yyyyMMdd" type="date" :disabled="employData.voucherId != '' && employData.voucherId != undefined"></el-date-picker>
                  </el-form-item>
              </el-col>
              <el-col :span="8" :offset="0" v-if="type != 'notIssued'"> <!-- 未开具发票不作展示 -->
                  <el-form-item label='发票代码' prop='invoiceCode' label-position="left">
                      <el-input v-model='employData.invoiceCode' maxlength="12" placeholder="请输入发票代码" :disabled="employData.voucherId != '' && employData.voucherId != undefined"/>
                  </el-form-item>
              </el-col>
              <el-col :span="8" :offset="0" v-if="type != 'notIssued'"> <!-- 未开具发票不作展示 -->
                  <el-form-item label='发票号码' prop='invoiceNo' label-position="left">
                      <el-input v-model='employData.invoiceNo' maxlength="8" placeholder="请输入发票号码" :disabled="employData.voucherId != '' && employData.voucherId != undefined"/>
                  </el-form-item>
              </el-col>
              <el-col :span="8" :offset="0" v-if="type != 'notIssued'"> <!-- 未开具发票不作展示 -->
                  <el-form-item label='是否作废' prop='status' required>
                      <el-select v-model="employData.status" placeholder="请选择" :disabled="employData.voucherId != '' && employData.voucherId != undefined">
                          <el-option v-for="item in DEFAULT_INVOICE_STATUS" :key="item.value" :label="item.name" :value="item.value">
                          </el-option>
                      </el-select>
                  </el-form-item>
              </el-col>
              <el-col :span="8" :offset="0" v-if="category == 1"> <!-- 销项为 客户名称 --> <!-- 未开具发票不作校验 -->
                  <el-form-item label='客户名称' prop='name' :rules="type != 'notIssued' ?[{ required: true, message: '请输入客户名称', trigger: 'blur',},
                ] : []" label-position="left" >
                      <el-autocomplete v-model="employData.name" :fetch-suggestions="querySearchAsync" placeholder="请输入客户名称" @select="handleSelect" :maxlength="40" :disabled="employData.voucherId != '' && employData.voucherId != undefined"></el-autocomplete>
                  </el-form-item>
              </el-col>
              <el-col :span="8" :offset="0" v-if="category == 2"> <!-- 进项为 供应商名称 --> <!-- 未开具发票不作校验 -->
                  <el-form-item label='供应商名称' prop='name' :rules="type != 'notIssued' ?[{ required: true, message: '请输入供应商名称', trigger: 'blur',},
                ] : []" label-position="left" >
                      <el-autocomplete v-model="employData.name" :fetch-suggestions="querySearchAsync" placeholder="请输入供应商名称" @select="handleSelect" :maxlength="40" :disabled="employData.voucherId != '' && employData.voucherId != undefined"></el-autocomplete>
                  </el-form-item>
              </el-col>
              <el-col :span="8" :offset="0"> <!-- 增值税普票/专票 ordinary/specialUse N/Y --> <!-- 增值税专票 作校验 -->
                  <el-form-item label='统一社会信用代码' prop="creditCode" :rules="type == 'specialUse' && category == '1' ?[{ required: true, message: '请输入统一社会信用代码', trigger: 'blur',},
                { pattern: '^[A-Z0-9]{18}$', message: '统一社会信用代码不合法', trigger: 'blur' },] : [{ pattern: '^[A-Z0-9]{18}$', message: '统一社会信用代码不合法', trigger: 'blur' }]">
                      <el-input v-model='employData.creditCode' maxlength="18" placeholder="请输入统一社会信用代码" :disabled="employData.voucherId != '' && employData.voucherId != undefined"/>
                  </el-form-item>
              </el-col>
              <el-col :span="8" :offset="0" v-if="type != 'notIssued'"> <!-- 未开具发票不作展示 --> <!-- 增值税专票 作校验 -->
                  <el-form-item label='地址及电话' prop="addressPhone" :rules="type == 'specialUse' && category == '1' ?[{ required: true, message: '请输入地址及电话', trigger: 'blur',}] : []">
                      <el-input v-model='employData.addressPhone' maxlength="100" placeholder="请输入地址及电话" :disabled="employData.voucherId != '' && employData.voucherId != undefined"/>
                  </el-form-item>
              </el-col>
              <el-col :span="8" :offset="0" v-if="type != 'notIssued'"> <!-- 未开具发票不作展示 --> <!-- 增值税专票 作校验 -->
                  <el-form-item label='开户行及账号' prop="accountBankNo" :rules="type == 'specialUse' && category == '1' ?[{ required: true, message: '请输入开户行及账号', trigger: 'blur',}] : []">
                      <el-input v-model='employData.accountBankNo' maxlength="50" placeholder="请输入开户行及账号" :disabled="employData.voucherId != '' && employData.voucherId != undefined"/>
                  </el-form-item>
              </el-col>

              
              <el-col :span="8" :offset="0" v-if="category == '2'"> <!-- 仅进项发票 作展示-->
                  <el-form-item label='认证日期'>
                    <!-- || ( $route.query.id && !employData.isUpdateAuthDate ) -->
                      <el-date-picker v-model="employData.authenticationDate" type="date" placeholder="请选择认证日期" value-format="yyyyMMdd" :disabled="employData.voucherId != '' && employData.voucherId != undefined"></el-date-picker>
                  </el-form-item>
              </el-col>

              <el-col :span="8" :offset="0" v-if="type == 'ordinary'"> <!-- 仅普票 作展示 -->
                  <el-form-item label='校验码' prop="checkCode">
                      <el-input v-model='employData.checkCode' maxlength="20" placeholder="请输入校验码" :disabled="employData.voucherId != '' && employData.voucherId != undefined"/>
                  </el-form-item>
              </el-col>
              <el-col :span="8" :offset="0" v-if="type == 'ordinary' || type == 'specialUse'"> <!-- 仅普票 作展示 -->
                  <el-form-item label='发票类别' prop='form' required>
                      <el-select v-model="employData.form" placeholder="请选择" required :disabled="employData.voucherId != '' && employData.voucherId != undefined">
                          <el-option v-for="item in DEFAULT_INVOICE_FORM" :key="item.value" :label="item.name" :value="item.value">
                          </el-option>
                      </el-select>
                  </el-form-item>
              </el-col>
              <el-col :span="8" :offset="0"  v-if="type == 'taxControl'"> <!-- 仅机动车专用发票 作展示 -->
                  <el-form-item label="车架号">
                      <el-input v-model='employData.frameNo' maxlength="17" placeholder="请输入车架号" :disabled="employData.voucherId != '' && employData.voucherId != undefined "/>
                  </el-form-item>
              </el-col>
              <el-col :span="8" :offset="0" v-if="type == 'taxControl'">  <!-- 仅机动车专用发票 作展示 -->
                  <el-form-item label="机器编号">
                      <el-input v-model='employData.machineCode' maxlength="12" placeholder="请输入机器编号" :disabled="employData.voucherId != '' && employData.voucherId != undefined"/>
                  </el-form-item>
              </el-col>
              <el-col :span="8" :offset="0" v-if="type == 'taxControl'">  <!-- 仅机动车专用发票 作展示 -->
                  <el-form-item label="主管税务机关代码">
                      <el-input v-model='employData.taxAuthorityCode' maxlength="7" placeholder="请输入主管税务机关代码" :disabled="employData.voucherId != '' && employData.voucherId != undefined"/>
                  </el-form-item>
              </el-col>
            <el-col :span="8" :offset="0">
                  <el-form-item label="备注">
                      <el-input v-model='employData.remark' maxlength="300" placeholder="请输入备注" :disabled="employData.voucherId != '' && employData.voucherId != undefined"/>
                  </el-form-item>
              </el-col>
          </el-row>

            <div class="headline">业务类型</div>
            <el-form-item label="模板" label-width="8rem" required>
                  <el-select v-model="employData.businessType" placeholder="请选择" class="formItem" :disabled="employData.voucherId != '' && employData.voucherId != undefined">
                      <el-option v-for="item in templateOptions" :key="item.value" :label="item.name" :value="item.value">
                      </el-option>
                  </el-select>
                  <span class="tips" @click="toPath">设置生成凭证模板</span>
            </el-form-item>

            <div class="headline">发票数据</div>
            <el-row v-if=" type != 'notIssued' && type !='taxControl'">  <!--  未开具发票、机动车票不作展示 -->
                <el-col :span="6" :offset="0">
                    <el-form-item label="是否录入发票明细" label-width="10rem" required>
                        <el-radio-group v-model="employData.isEnterDetail" @change="insurance" :disabled="employData.voucherId != '' && employData.voucherId != undefined">
                            <el-radio label="1" value="1">是</el-radio>
                            <el-radio label="0" value="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <div v-if="employData.isEnterDetail == '1'">
                  <el-col :span="6" :offset="0" >
                      <el-form-item label="不含税金额"  label-width="6rem" required v-if="employData.isEnterDetail == '1'">
                          <el-input v-model="employData.taxExcludedAmount" 
                          class="proportion" @input="checkInput(employData)" @blur="count(employData)" :disabled="employData.isEnterDetail == '1' || employData.voucherId != '' && employData.voucherId != undefined"></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="6" :offset="0" >
                      <el-form-item label="税额" label-width="5rem" required v-if="employData.isEnterDetail == '1'" >
                          <el-input v-model="employData.taxAmount" 
                          class="proportion" @input="checkInput(employData)" @blur="count(employData)" :disabled="employData.isEnterDetail == '1'|| employData.voucherId != '' && employData.voucherId != undefined"></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="6" :offset="0" >
                      <el-form-item label="价税合计" label-width="5rem" required v-if="employData.isEnterDetail == '1'">
                          <el-input v-model="employData.amountTotal" class="proportion" disabled></el-input>
                      </el-form-item>
                  </el-col>
                </div>
                <div v-if="employData.isEnterDetail == '0'" style="width:97%;margin-left:2%;display: inline-block;">
                    <el-col :span="6" :offset="0" >
                        <el-form-item label="不含税金额" prop='taxExcludedAmount' label-width="6rem" :rules="[{ required: true, message: '请输入不含税金额', trigger: 'blur',}]">
                            <el-input  v-model="employData.taxExcludedAmount" 
                            class="proportionInput" @input="checkInput(employData)" @blur="taxRateChange(employData)" :disabled="employData.voucherId != '' && employData.voucherId != undefined"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" :offset="0" >
                      <el-form-item label="税率" prop='taxRate' label-width="6rem" :rules="[{ required: true, message: '请选择税率', trigger: 'change',}]">
                        <el-select v-model="employData.taxRate" placeholder="请选择" filterable class="proportionInput" @change="taxRateChange(employData)" :disabled="employData.voucherId != '' && employData.voucherId != undefined">
                          <el-option v-for="item in DEFAULT_INVOICE_TAXRATE" :key="item.value" :label="item.name + '%'" :value="item.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6" :offset="0" >
                        <el-form-item label="税额" prop='taxAmount' label-width="5rem" :rules="[{ required: true, message: '请输入税额', trigger: 'blur',}]">
                            <el-input  v-model="employData.taxAmount" 
                            class="proportionInput" @input="checkInput(employData)" @blur="count(employData)" :disabled="employData.voucherId != '' && employData.voucherId != undefined"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" :offset="0" >
                        <el-form-item label="价税合计" label-width="5rem" required >
                            <el-input v-model="employData.amountTotal" class="proportion" disabled></el-input>
                        </el-form-item>
                    </el-col>
                </div>
            </el-row>
            <employTable v-model="employData.accInvoiceDetails" :type='type' v-if="employData.isEnterDetail == '1'" v-loading="insurLoading" />
          </el-form>
        </div>

        <save-dialog :visible="saveDialog" :cancelDialog="cancelDialog" :confirmDialog="confirmDialog" :isdeling="isSaving" :config="saveConfig">
            <div class="answer">亲，您已录入发票数据，确定要清除，重新录入发票明细吗？</div>
        </save-dialog>

        <msg icon="iconfont iconicon1-17" color='#35C4B1' :message="message" :promptVisible="promptVisible" :column="true" :row="false" top="22vh" class="employ_msg" :showClose="true" />

        <!-- 亲，您已录入发票数据，确定要清除，重新录入发票明细吗？ -->
  </div>
</template>

<script>
/**
 * 发票 新增发票 表单组件
 * @author zs
 * @since 2021-8-25
 */
  import employTable from './employTable.vue'
  import saveDialog from '@/components/Dialog'
  import VMoneyFormat from '@/directive/moneyFormat';
  import axios from 'axios';
  import moment from 'moment'        
//   import { accAccountCompany } from '@/api/accountSets'  //客户关联社会信用代码
//   import { DEFAULT_INVOICE_STATUS , DEFAULT_INVOICE_FORM , DEFAULT_INVOICE_TAXRATE} from '@/common/constant'
  import { plusOrMinus , decimalPoint} from '@/utils'
  export default {
    props: {
      type: {    // 新增发票类别
        type: String,
      },
      typeId :{  // 新增发票类别Id
        type: String,
      },
      category:{ // 销项进项
        type: String,
      },
      config: {
        type: Object,
        default: () => {
          return { precision: 2, thousands: ',' }
        }
      }
    },
    directives: { MoneyFormat: VMoneyFormat },
    components: { employTable, saveDialog },
    data() {
      return {
        showLoading: false,
        isLoading: false,
        isSaving: false,
        insurLoading: false,
        saveDialog: false,
        promptVisible: false,
        message: '',
        maxlength: 18,
        
        employData: {
          asId: this.getToken('asId'),   //账套ID
          accountBankNo: "", //开户行及账号
          addressPhone: "",  //地址及电话
          authenticationDate: "", //认证日期
          billingDate: "",        //开票日期
          businessType: "",       //业务类型
          checkCode: "",          //校验码
          creditCode: "",         //统一社会认证编码
          form: "01",             //发票类别
          frameNo: "",            //车架号
          id: 0,
          invoiceCode: "",        //发票代码
          invoiceNo: "",          //发票号码
          isEnterDetail: "1",      //是否录入发票明细
          machineCode: "",        //机器编号
          name: "",               //名称
          remark: "",             //备注
          status: '01',           // 01 正常 00 作废  //发票状态
          taxAuthorityCode: "",   //税务机关代码
          taxExcludedAmount: '',   //金额
          taxRate : 17,            //税率
          taxAmount: undefined,           //税额
          amountTotal : '' ,       //价税合计
          type: "",               // type 新增发票类别
          accInvoiceDetails: []  //五险一金扣除项目
        },

        rules: {
          billingDate: [
            { required: true, message: '请选择开票日期', trigger: 'blur' }
          ],
          invoiceCode: [
            { required: true, message: '请输入发票代码', trigger: 'blur' },
            { pattern: '^[0-9]{10}$|[0-9]{12}$', message: '发票代码格式不正确', trigger: 'blur' }
          ],
          invoiceNo: [
            { required: true, message: '请输入发票号码', trigger: 'blur' },
            { pattern: '^[0-9]{8}$', message: '发票号码格式不正确', trigger: 'blur' }
          ],
          phone: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            { pattern: '^1[3|4|5|7|8|9][0-9]{9}$', message: '账号格式不正确，请输入正确的账号', trigger: 'blur' }
          ],
          email: [
            { required: true, message: '请输入账户邮箱', trigger: 'blur' },
            { pattern: '^[a-z0-9A-Z]+[- | a-z0-9A-Z . _]+@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\\.)+[a-z]{2,}$', message: '账户不合法，请输入正确的账户邮箱', trigger: 'blur' }
          ],
          taxExcludedAmount:[
            { required: true, message: '请输入不含税金额', trigger: 'blur',}
          ],
          taxAmount:[
            { required: true, message: '请输入税额', trigger: 'blur',}
          ],
          checkCode:[{ pattern: '^[a-z0-9A-Z]{20}$', message: '校验码格式不正确', trigger: 'blur' }]
        },
        DEFAULT_INVOICE_FORM:[],   //发票类型
        DEFAULT_INVOICE_STATUS:[], //发票状态
        DEFAULT_INVOICE_TAXRATE:[] , //税率
        templateOptions:[{value: '1', name: '销售收入' },],
        categoryTree: [],
        restaurants: [],
        defaultSubjecTreeProps: {
          id: 'id',
          label: 'fullName'
        },
        subjectName: 'first',
        newEmployData: {},
        saveConfig: {
          top: '20vh',
          width: '21rem',
          title: '提示',
          center: true,
          btnTxt: ['确定', '取消'],
        },
        storageData :[],
        storageLength : 0,
      }
    },
    methods: {
      toPath(){
        let type = !this.$route.query.id ? '1' : '0'; //新增、编辑判断
        let path = this.category == '1' ? 'writeOff' : 'income'; // 1:销项，2:进项
        this.$router.push('/invoice/'+path); //路由重置
        setTimeout(()=>{
          this.$router.push('/invoice/'+path+'/template?invoice='+ type)
        },200)
      },
      insurance(val) {
        if (val == '1') {
          if (!this.$route.query.id) { //新增页逻辑
            if((this.employData.taxExcludedAmount && (this.employData.taxExcludedAmount * 1) !== 0) || (this.employData.taxAmount && (this.employData.taxAmount * 1) !== 0) ){
              this.employData.isEnterDetail = '0' ;
              this.saveDialog = true;
            }else{
              this.getInsurancesList();
            }
          }else{ //编辑页逻辑
            if(this.storageLength == 0){
              if((this.employData.taxExcludedAmount && (this.employData.taxExcludedAmount * 1) !== 0) || (this.employData.taxAmount && (this.employData.taxAmount * 1) !== 0) ){
                this.employData.isEnterDetail = '0' ;
                this.saveDialog = true;
              }else{
                this.getInsurancesList();
              }
            }else{
              this.employData.accInvoiceDetails = this.storageData;
              this.initArr();
            }
          }
        }else{
          this.employData.taxExcludedAmount = undefined
          this.employData.taxAmount = undefined
          this.employData.taxRate = 17
          this.employData.amountTotal = undefined
        }
      },
      submitForm(formName) {  // 表单提交
        let amountNum=0,taxAmountNum=0,tradeNameNum=0,num=0;
        let status = false;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.employData.isEnterDetail == '0') { // 是否录入发票明细
              this.employData.accInvoiceDetails = [];
              status = true
            }else {
              //校验发票明细数据
                for (let i = 0; i < this.employData.accInvoiceDetails.length; i++) {
                  let item = this.employData.accInvoiceDetails[i];
                  if (item.amount != '' ) { amountNum += 1 };
                  if (item.taxAmount != undefined && item.taxAmount !== '') { taxAmountNum += 1 };
                  if ( this.type != 'notIssued' ){ if (item.tradeName != '') { tradeNameNum += 1 }  } else { tradeNameNum = 1 };
                  if ( this.type == 'taxControl' ){ if (item.num != '' && item.num * 1 != 0) { num += 1 }  };
                }
                let Type = this.type;
       
                if ((amountNum != 0) && (taxAmountNum != 0) && (tradeNameNum !=0) && (amountNum === taxAmountNum && taxAmountNum === tradeNameNum)) {  //校验
                  status = true
                }else{
                  try {
                    this.employData.accInvoiceDetails.map((item, index) => {  //未取值判断
                      if (!item.tradeName && Type != 'notIssued'){
                        throw new Error(`亲，发票数据第${index + 1}行，请输入商品名称`)
                      }else if (!item.amount || item.amount == '') {
                        throw new Error(`亲，发票数据第${index + 1}行，请输入金额`)
                      } else if (item.taxAmount == undefined || item.taxAmount === '') {
                        throw new Error(`亲，发票数据第${index + 1}行，请输入税额`)
                      }else if ( ( item.num != '' && (item.num * 1) == 0 ) && Type == 'taxControl' ) {
                        throw new Error(`发票数量不能为空`)
                      }
                    })
                  } catch (error) {
                    this.$message.error(error.message)
                    return
                  }
                }
            }
            if(this.type == 'taxControl' && num == 0){
              this.$message.error('发票数量不能为空')
              return
            }
            if( (this.typeId == '02' || this.typeId == '03') && this.employData.taxExcludedAmount == 0){  // 专票|普票
              this.$message.error('不含税金额不能为0')
              return
            }
            if (status) {
              let list = this.employData.accInvoiceDetails.filter(function (item) {
                return item.amount != '' // 过滤出空值 list
              });        
              this.newEmployData = { ...this.employData,accInvoiceDetails:list }
              this.newEmployData.type = this.typeId
              this.newEmployData.billingDate = moment(this.newEmployData.billingDate).format("YYYYMMDD")
              if(this.newEmployData.authenticationDate && this.newEmployData.authenticationDate != ''){
                this.newEmployData.authenticationDate = moment(this.newEmployData.authenticationDate).format("YYYYMMDD")
              }
              if (this.$route.query.id) {    // 编辑发票
                this.add('post', '/api/invoice/invoice/editInvoice', this.newEmployData)
              } else {
                if (this.category == '1') {  // 新增销项发票
                  this.add('post', '/api/invoice/invoice/addOutInvoice', this.newEmployData)
                }else{                       // 新增进项发票
                  this.add('post', '/api/invoice/invoice/addInInvoice', this.newEmployData)
                }
              }
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      add(method, url, data) {
        this.isdeling = true
        this.isLoading = true
        axios({
          method: method,
          url: url,
          data: data,
          headers: {
            'Content-Type': "application/json;charset=utf-8",
            'Authorization': this.getCookies('Authorization'),
            'Group':'eayc',
            'Tenat':'asId',
          },
        }).then(res => {
          if (res.data.success) {
            if (url != '/api/invoice/invoice/editInvoice') {  //新增逻辑
              if (res.data.msg) {
                  this.message = '新增成功！'
                  this.promptVisible = true
                  setTimeout(() => {
                    this.promptVisible = false;
                    this.$router.go(-2);
                  }, 500)
              } else {
                this.saveDialog = true  // 二次数据校验弹窗
                return
              }
            } else { //编辑逻辑
              this.message = res.data.msg
              this.promptVisible = true
              setTimeout(() => {
                this.promptVisible = false;
                this.$router.go(-1);
              }, 500)
            }
          } else {
            this.$message.error(res.data.msg)
          }
          this.isLoading = false
          this.isdeling = false
          this.delDialog = false
        }).catch(err => {
          this.$message.error(err)
          this.isLoading = false
          this.isdeling = false
        })
      },
      cancelDialog() {
        this.delDialog = false
        this.saveDialog = false
        this.isLoading = false
      },
      confirmDialog() {
        this.isSaving = true;
        setTimeout(()=>{
          this.isSaving = false;
          this.saveDialog = false;

          this.employData.isEnterDetail = '1' ;
          this.employData.taxExcludedAmount = undefined;
          this.employData.taxAmount = undefined;
          this.employData.amountTotal = undefined;
          if (!this.$route.query.id) { //新增页逻辑
              this.getInsurancesList();
          }else{                       //编辑页逻辑
            if(this.storageData.length === 0){
              this.getInsurancesList();
            }else{
              this.employData.accInvoiceDetails = this.storageData
              this.initArr();
            }
          }
        },500)
      },
      back() {
        this.$router.go(-1)
      },

      getBusinessTypes(){ //获取发票模板
        this.$store.dispatch("BusinessTypes", { // LoadVoucher
            asId: this.getToken('asId'),
            invoiceType: this.category,  //发票类型（1:销项，2:进项）
          }).then(res => {
            if (res.success) {
              this.templateOptions = res.data
              if (this.templateOptions.length > 0 && !this.$route.query.id ) { // 新增默认展示
                this.employData.businessType = this.templateOptions[0].value
              }
            } else {
              this.$message.error(res.msg)
            }
          }).catch(err => {
            this.$message.error(err)
          })
      },
      handleSelect(item) {
        console.log(item);
        this.employData.creditCode = item.creditcode;
      },
      // 发票数据
      getInsurancesList() {
        this.insurLoading = true;
        let num = this.type != 'notIssued' && this.type !='taxControl' ? 5 : 1;
        this.employData.accInvoiceDetails = [];
        for(let i=0 ;i < num; i++ ){
          this.employData.accInvoiceDetails.push({
            amount : '',      //金额
            model : "",      //规格型号
            num : '',         //数量
            taxAmount : undefined,   //税额
            taxAmountCount : '',     //税额计算
            taxItems : "01",   //征税项目
            taxMethod : "01",  //计税方法
            taxRate : 17,    //税率
            tradeName : "",  //商品名称
            unit : "" , //单位})
          })
        }
        setTimeout(()=>{
          this.insurLoading = false;
        },1000)
      },
      initArr(){
        let taxExcludedAmount = 0,taxAmount = 0,amountTotal =0;
        this.employData.accInvoiceDetails.map(item=>{
            if(item.taxAmount == undefined){ item.taxAmountCount = 0 } else { item.taxAmountCount = item.taxAmount }
            if(item.amount == undefined){ item.amount = 0}

            taxExcludedAmount += item.amount * 1;   //金额
            taxAmount += item.taxAmountCount * 1;  //税额
            amountTotal += item.taxAmountCount * 1 + item.amount * 1;  //价税合计
        });
        this.employData.taxExcludedAmount = taxExcludedAmount.toFixed(2)//金额
        this.employData.taxAmount = taxAmount == undefined ? undefined : taxAmount.toFixed(2) //税额
        this.employData.amountTotal = amountTotal == undefined ? undefined : amountTotal.toFixed(2)  //价税合计
      },
      checkInput(data) {
        data.taxAmount = plusOrMinus(data.taxAmount + '');
        data.taxExcludedAmount = plusOrMinus(data.taxExcludedAmount + '');
      },
      taxRateChange(item) {  //金额 * 基础税率
        item.taxExcludedAmount = decimalPoint(item.taxExcludedAmount);
        item.taxAmount = (item.taxExcludedAmount * (item.taxRate / 100)).toFixed(2);
        item.amountTotal = (item.taxAmount * 1 + item.taxExcludedAmount * 1).toFixed(2);
        if(item.taxAmount == undefined || item.taxExcludedAmount == undefined){item.amountTotal = undefined}
      },
      count(item){
        item.taxAmount = decimalPoint(item.taxAmount);
        item.amountTotal = (item.taxAmount * 1 + item.taxExcludedAmount * 1).toFixed(2);
        if(item.taxAmount == undefined || item.taxExcludedAmount == undefined){item.amountTotal = undefined}
      },
      // 编辑信息
      getDetail() {
        this.showLoading = true;
        this.$store.dispatch("QueryInvoiceDetail", { // 发票详情查询
            asId: this.getToken('asId'),
            id: this.$route.query.id,
          }).then(res => {
            if (res.success) {
              this.employData = res.data;
              let num = this.type != 'notIssued' && this.type !='taxControl' ? 5 : 1;
              let list = res.data.accInvoiceDetails.map((item, index) => {  //找到对应的template key值 覆盖之前的集合数据
                  item.taxRate = item.taxRate * 1;
                  item.taxAmountCount = item.taxAmount;
                  return item;
              });
              let length = num - list.length;
              this.storageLength = list.length
              this.storageData = list;  //重组编辑 列表数据
              console.log(list);
              if (length > 0) {
                for(let i = 0 ;i < length; i++ ){
                  this.storageData.push({
                    amount : '',      //金额
                    model : "",      //规格型号
                    num : '',         //数量
                    taxAmount : undefined,   //税额
                    taxAmountCount : '',     //税额计算
                    taxItems : "01",   //征税项目
                    taxMethod : "01",  //计税方法
                    taxRate : 17,    //税率
                    tradeName : "",  //商品名称
                    unit : "" , //单位})
                  })
                }
              }
              this.employData.taxRate = res.data.taxRate * 1;
              this.employData.amountTotal = (res.data.taxExcludedAmount + res.data.taxAmount).toFixed(2);
              this.employData.billingDate = moment(res.data.billingDate).format("YYYYMMDD");
              if(res.data.authenticationDate){this.employData.authenticationDate = moment(res.data.authenticationDate).format("YYYYMMDD");}
              this.showLoading = false;
            } else {
              this.$message.error(res.msg);
              this.showLoading = false
            }
          }).catch(err => {
            this.$message.error(err)
          })
      }
    },
    mounted() {
      this.getBusinessTypes();  //获取 发票模板业务类型
      if (this.$route.query.id) {
        this.getDetail()
      } else {
        this.getInsurancesList()
      }
    },
    watch: {
      'employData.accInvoiceDetails': {
        handler(val, oldVal) {
          if ( val && this.employData.isEnterDetail == '1') { // 选中需要明细是 重新计算数据
            this.initArr();  //计算
          }
        },
        deep: true,
        // immediate: true
      },
    }
  }
</script>

<style lang="less" scoped>
  .app-container {
    // background-color: #fff;
    // padding: 0 20px;
    height: 100%;
    overflow-y: auto;
    .container{
      padding: 0 20px 50px;
      background: #fff;
      min-height: 89%;
    }
    .title {
      height: 59px;
      line-height: 59px;
      text-align: center;
      font-size: 18px;
      font-weight: Bold;
    }
    .headline {
      border-bottom: 1px solid #dcdcdc;
      padding: 18px 0 18px 16px;
      margin-bottom: 24px;
      position: relative;
      font-size: 18px;
      &::after {
        content: "";
        width: 4px;
        height: 16px;
        background-color: #4f71ff;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .employ_form {
        .part {
            display: flex;
            flex-wrap: wrap;
            // .el-form-item {
            //   width: 33.3%;
            // }
            .el-date-editor{
                width: 320px;
            }
            .el-input{
                width: 320px;
            }
            .el-select {
                width: 320px;
            //   width: 100%;
            }
            .el-autocomplete{
                width: 320px;
            }
        }
        .formItem{
            width: 320px;
        }
        .tips{
            cursor: pointer;
            color: #4F71FF;
            font-size: 14px;
            margin-left: 20px;
        }
    }
    .footer {
      text-align: center;
      margin: 50px 0 50px;
      .able {
        margin-left: 20px;
      }
      .btn {
        width: 110px;
        height: 38px;
      }
    }
  }
  .provisionSub {
    position: initial !important;
    display: block !important;
  }
  .employ_msg {
    /deep/.el-dialog {
      // height: 160px;
      width: 280px !important;
      border-radius: 8px;
    }
  }
  .proportion {
    width: 88% !important;
    /deep/.el-input__inner {
      text-align: right;
    }
  }
  .proportionInput {
    width: 88% !important;
    /deep/.el-input__inner {
      text-align: right;
    }
  }
</style>